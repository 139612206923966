import React from "react";
import NavigationStack from "./Components/NavigationStack";

const App = () => {
  return (
    <>
      <NavigationStack />
    </>
  );
};

export default App;
